import React from 'react';
import './style/App.css';
import { Route } from "react-router-dom"
import { BrowserRouter as Router } from "react-router-dom";
import { WelcomePage } from "./pages/welcome";
import StarManager from './components/StarManager';
import { ResumePage } from './pages/resume';
import { ProjectsPage } from './pages/projects';
import { AboutPage } from './pages/about';

function App() {
  return (
    <div className="App">
      <Router> 
        <Route path="/" exact component={WelcomePage} />
        <Route path="/resume">
          <ResumePage/>
        </Route>
        <Route path="/about">
          <AboutPage/>
        </Route>
        <Route path="/projects">
          <ProjectsPage/>
        </Route>
      </Router>
      <StarManager centerPadding={0}/>
    </div>
  );
}

export default App;
