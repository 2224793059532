import React, { Component } from 'react';
import TextPage from '../components/TextPage';

class AboutPage extends Component {

  render() {
    const textElements = <div>
        <br/>
        <p>I am a software engineer located in Tucson, AZ. I received my Masters of Science in CS in 2019 from the University of Arizona. Currently working for Amadeus IT Group.</p>
    </div>;

    return (
      <div> 
        <TextPage title={'About'} textElements={textElements}/>
      </div>
    );
  }
}

export { AboutPage };
