import React, { Component } from 'react';
import CenterCircle from '../components/CenterCircle';

class WelcomePage extends Component {

  render() {
    var centerCircleRadius = 200;
    var centerData = [{
      text: "About",
      route: "about"
    }, 
    {
      text: "Resume",
      route: "resume"
    }, 
    {
      text: "Projects",
      route: "projects"
    }];
    return (
      <div> 
        <CenterCircle centerCircleRadius={centerCircleRadius} data={centerData}/>
      </div>
    );
  }
}

export { WelcomePage };
