import React, { Component } from 'react';
import rd3 from 'react-d3-library';
import { getNodeAndInitStars } from '../util/d3Background';
const RD3Component = rd3.Component;

class StarManager extends Component {
   
    constructor(props) {
        super(props);
        this.state = {d3: '', centerPadding: props.centerPadding}
    }
    
    componentDidMount() {
        this.setState({d3: getNodeAndInitStars(this.state.centerPadding)});
    }

    render() {
        return (
            <div>
             <RD3Component data={this.state.d3}/>
            </div>
        );
    }
}

export default StarManager;
