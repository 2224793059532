import * as d3 from "d3";

var ROTATION_TRANSITION_DURATION_MILLIS = 50; 
var SIZE_TRANSITION_DURATION_MILLIS = 1000;
var BACKGROUND_CIRCLE_CLASS = 'circleboi';

var w = window.innerWidth;
var h = window.innerHeight;
var area = w * h;
var numPoints = randomIntFromInterval(area / 5000, area / 3000);
// var numPoints = 20;
var node = document.createElement('div')
var svg = d3
    .select(node)
    .append("svg")
    .attr('class', 'background')
    .attr('width', '100vw')
    .attr('height', '100vh');    

export function getNodeAndInitStars(centerPadding) {

    for (var i = 0; i < numPoints; i++) {
        var point = randomPointFromInterval(0, w, 0, h, centerPadding);
    
        svg.append('circle')
            .style('stroke', 'gray')
            .style('fill', 'white')
            .attr('r', randomIntFromInterval(1, 3))
            .attr('cx', point.x)
            .attr('cy', point.y)
            .attr('a', 0)
            .attr('direction', flipCoin() ? 1 : -1)
            .attr('speed', 1 * Math.random())
            .attr('class', BACKGROUND_CIRCLE_CLASS);
    }

    svg.on('load', function() {
        applyRotationTransition();
        applySizeTransition(true);
       });

    return node;
}

function applySizeTransition(increase) {
    var n = numPoints;
    d3.selectAll('circle')
        .filter("." + BACKGROUND_CIRCLE_CLASS)
        .transition('size')
        .ease(d3.easeLinear)
        .duration(SIZE_TRANSITION_DURATION_MILLIS)
        .attr('r', function() {
            const r = parseInt(d3.select(this).attr('r'));
            return increase ? r + 1 : r - 1;
        })
        .on("end", () => {
            n--;
            if (n === 0) {
                applySizeTransition(!increase)
            }
        });
}

function applyRotationTransition() {
    var n = numPoints;
    d3.selectAll('circle')
        .filter("." + BACKGROUND_CIRCLE_CLASS)
        .transition('rotation')
        .ease(d3.easeLinear)
        .duration(ROTATION_TRANSITION_DURATION_MILLIS)
        .attr('cx', function() {
            const point = d3.select(this);
            return updatePointX(point)
        })
        .attr('cy', function() {
            const point = d3.select(this);
            return updatePointY(point)
        })
        .on("end", () => {
            n--;
            if (n === 0) {
                applyRotationTransition()
            }
        });
}

// TODO can we calculate new x,y at the same time
function updatePointY(d) {
    const x = d.attr('cx'); // get x of center of circle that is data point
    const y = d.attr('cy'); // get y of center of circle that is data point
    const direction = d.attr('direction');
    const speed = d.attr('speed')

    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const cy = height / 2; // cy is center of circle that is the screen
    const cx = width / 2; // cx is center of circle that is screen

    const currentAInRadians = Math.atan2(y - cy, x - cx);
    const currentAInDegrees = currentAInRadians * (180/Math.PI);
    const a = (currentAInDegrees + (direction * (1 * speed))) * (Math.PI / 180);

    const r = distance(x, y, cx, cy);
    return cy + r * Math.sin(a);
}

function updatePointX(d) {
    const x = d.attr('cx'); // get x of center of circle that is data point
    const y = d.attr('cy'); // get y of center of circle that is data point
    const direction = d.attr('direction');
    const speed = d.attr('speed')

    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const cy = height / 2; // cy is center of circle that is the screen
    const cx = width / 2; // cx is center of circle that is screen

    const currentAInRadians = Math.atan2(y - cy, x - cx);
    const currentAInDegrees = currentAInRadians * (180/Math.PI);
    const a = (currentAInDegrees + (direction * (1 * speed))) * (Math.PI / 180);

    const r = distance(x, y, cx, cy);
    return cx + r * Math.cos(a);
}

function distance(xa, ya, xb, yb) {
    return Math.abs(Math.sqrt(Math.pow(xb - xa, 2) + Math.pow(yb - ya, 2)));
}

// centerPadding allows us to specify a distance from the center to not allow a point
function randomPointFromInterval(xMin, xMax, yMin, yMax, centerPadding=0) { 

    var center = {
        x: xMax / 2,
        y: yMax / 2
    };

    var point = {
        x: Math.floor(Math.random() * (xMax - xMin + 1) + xMin),
        y: Math.floor(Math.random() * (yMax - yMin + 1) + yMin)
    };

    if (distance(center.x, center.y, point.x, point.y) <= centerPadding) {
        // Just make the point fall outside of the center, no real trick here, just dumb math
        point = {
            x: point.x > center.x - centerPadding ? point.x + centerPadding * 2 : point.x - centerPadding * 2,
            y: point.y > center.y - centerPadding ? point.y + centerPadding * 2 : point.y - centerPadding * 2
        }
    }

    return point;
}

function randomIntFromInterval(min, max) { 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function flipCoin() {
    return Math.random() > 0.5;
}