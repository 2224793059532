import React, { Component } from 'react';
import '../style/TextPage.css';
import { Redirect } from "react-router-dom";

class TextPage extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            textElements: props.textElements,
            redirect: false
        }

        this.backClick = this.backClick.bind(this);
    }
    
    render() {
        const redirect = this.state.redirect ? <Redirect to='/'/> : null;
        return (
            <div>
                {redirect}
                <div className={'textPageParentDiv'}>
                    <div className={'textPageTitle'}> {this.state.title} </div>
                    <div className='textPageBackButton' onClick={this.backClick}> {'<Back>'} </div>
                    <div className={'textPageTitleLine'}> </div>
                    <div className={'textPageTextElementsDiv'}> {this.state.textElements} </div>
                </div>
            </div>
        );
    }

    backClick() {
        this.setState({redirect: true});
    }
}

export default TextPage;
