import React, { Component } from 'react';
import TextPage from '../components/TextPage';

class ProjectsPage extends Component {

  render() {
    const textElements = <div>
        <br/>
        <p>Find my github <a href='https://github.com/dpdicken'>here</a>.</p>
        <p>Here is a list of varius projects:</p>
        <ul>
            <li>Nothing yet!</li>
        </ul>
    </div>;

    return (
      <div> 
        <TextPage title={'Projects'} textElements={textElements}/>
      </div>
    );
  }
}

export { ProjectsPage };
