import React, { Component } from 'react';
import '../style/CenterCircle.css';
import { updatePieSliceSize, updatePieSliceString} from '../util/d3Pie';
import { Redirect } from "react-router-dom";

const HOVER_ARC_MAGNITUDE_CHANGE = 0.2;
const TEXT_ARC_MAGNITUDE_CHANGE = -0.05;

class CenterCircle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            centerCircleRadius: props.centerCircleRadius, 
            data: props.data,
            routeComponent: null
        }
        this.routeClick = this.routeClick.bind(this)
    }

    render() {
        const data = this.state.data;
        const slices = this.createSlices(data, this.state.centerCircleRadius);

        return (
          <div>
            {this.state.routeComponent}
            <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" style={{position: 'fixed'}}>
                {slices}
            </svg>
          </div>
        );
    }

    createSlices(data, radius) {
        const numSlices = data.length;
        const percentPerSliceDecimal = (100 / numSlices) / 100;

        // fix dis shit
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;    
        const cy = height / 2; // cy is center of circle that is the screen
        const cx = width / 2; // cx is center of circle that is screen

        const className = 'pieSlice';
        var elems = []
        var i = 0;
        for (i = 0; i < numSlices; i++) {  
            const startPercent = ((100 * percentPerSliceDecimal) * i) / 100;
            const endPercent = ((100 * percentPerSliceDecimal) * (i + 1)) / 100;

            const startCoords = getCoordinatesForPercentAndNormalize(startPercent, radius, cx, cy);
            const endCoords = getCoordinatesForPercentAndNormalize(endPercent, radius, cx, cy);

            const normal = computeNormalValues(cx, cy, radius, startCoords, endCoords);
            const hover = computeOffsetValues(cx, cy, radius, startPercent, endPercent, HOVER_ARC_MAGNITUDE_CHANGE);

            var textValues = computeOffsetValues(cx, cy, radius, startPercent, endPercent, TEXT_ARC_MAGNITUDE_CHANGE);
            var tempTextPathString = getTextArc(textValues, 1);
            var textHoverValues = computeOffsetValues(cx, cy, radius, startPercent, endPercent, HOVER_ARC_MAGNITUDE_CHANGE - Math.abs(TEXT_ARC_MAGNITUDE_CHANGE));
            var tempTextHoverPathString = getTextArc(textHoverValues, 1);
            if ((normal.endY - normal.startY) > (cy - normal.startY)) {
                textValues = computeOffsetValues(cx, cy, radius, endPercent, startPercent, TEXT_ARC_MAGNITUDE_CHANGE);
                tempTextPathString = getTextArc(textValues, 0);
                textHoverValues = computeOffsetValues(cx, cy, radius, endPercent, startPercent, HOVER_ARC_MAGNITUDE_CHANGE - Math.abs(TEXT_ARC_MAGNITUDE_CHANGE));
                tempTextHoverPathString = getTextArc(textHoverValues, 0);
            }
            const textPathString = tempTextPathString;
            const textHoverPathString = tempTextHoverPathString;

            const textPathElementId = 'text-path-' + i;
            const textPathArc = <path id={textPathElementId} key={textPathElementId} d={textPathString} fill='none'/>;

            const sliceId = className + '-' + i;
            const pathString = getPathString(normal);
            elems.push(<path onMouseOver={() => mouseEnter(sliceId, hover, textPathElementId, textHoverPathString)}
                             onMouseLeave={() => mouseLeave(sliceId, normal, textPathElementId, textPathString)}
                             onClick={(o) => this.routeClick(o)}
                             id={sliceId}
                             className={className} 
                             d={pathString} 
                             fill={'#1a1a1a'} 
                             stroke={'#aaaaaa'}
                             strokeWidth="3px"
                             key={'path-' + i}
                             route={data[i].route}/>);

            const text = <text className={'circleText'} 
                               key={'text-' + i} 
                               fill={'white'}
                               onMouseOver={() => mouseEnter(sliceId, hover, textPathElementId, textHoverPathString)}
                               onMouseLeave={() => mouseLeave(sliceId, normal, textPathElementId, textPathString)}
                                onClick={(o) => this.routeClick(o)}>
                <textPath startOffset="50%" 
                          textAnchor="middle" 
                          alignmentBaseline="top" 
                          href={'#' + textPathElementId}>{data[i].text} 
                </textPath>
            </text>;
            elems.push(textPathArc);
            elems.push(text);
        }

        return elems;
    }
 
    routeClick(o) {
        const route = o.currentTarget.getAttribute('route');
        this.setState({routeComponent: <Redirect to={"/" + route}/>});
    }

}

function computeNormalValues(cx, cy, radius, startCoords, endCoords) {
    return {
        cx: cx,
        cy: cy,
        radius: radius,
        startX: startCoords.x,
        startY: startCoords.y,
        endX: endCoords.x,
        endY: endCoords.y
    }
}

function computeOffsetValues(currentCx, currentCy, radius, startPercent, endPercent, offsetDecimal) {
    currentCy = parseInt(currentCy)
    currentCx = parseInt(currentCx)

    const startCoords = getCoordinatesForPercentAndNormalize(startPercent, radius * (1 + offsetDecimal), currentCx, currentCy);
    const startX = startCoords.x;
    const startY = startCoords.y;

    const endCoords = getCoordinatesForPercentAndNormalize(endPercent, radius * (1 + offsetDecimal), currentCx, currentCy);
    const endX = endCoords.x;
    const endY = endCoords.y;

    const addedVectorsPoint = {
        x: (startX - currentCx) + (endX - currentCx) + currentCx,
        y: (startY - currentCy) + (endY - currentCy) + currentCy
    }

    const slope = Math.abs((addedVectorsPoint.y - currentCy) / (addedVectorsPoint.x - currentCx));
    var distance = Math.sqrt(Math.pow(addedVectorsPoint.x - currentCx, 2) + Math.pow(addedVectorsPoint.y - currentCy, 2));
    // Account for the distance we wont move if slope of y is 0.
    if (Math.floor(slope) === 0) {
        distance *= 2;
    }
    const moveX = addedVectorsPoint.x < currentCx ? -1 * distance * offsetDecimal : distance * offsetDecimal;
    const moveY = addedVectorsPoint.y < currentCy ? -1 * distance * offsetDecimal* slope : distance * offsetDecimal * slope;
    const cx = currentCx + moveX;
    const cy = currentCy + moveY;

    return {
        cx: cx,
        cy: cy,
        radius: radius * (1 + offsetDecimal),
        startX: startX + moveX,
        startY: startY + moveY,
        endX: endX + moveX,
        endY: endY + moveY
    }
}

function mouseEnter(id, hover, textId, textPath) {
    const newPath = getPathString(hover); 
    updatePieSliceSize(id, newPath);
    updatePieSliceString(textId, textPath);
}

function mouseLeave(circleId, normal, textId, textPath) {
    const newPath = getPathString(normal);
    updatePieSliceSize(circleId, newPath);
    updatePieSliceString(textId, textPath);
}

function getPathString(pathData) {
    return "M " +  pathData.cx + " " + pathData.cy + " "  // start in the center of the screen
    + "L " + pathData.startX + " " + pathData.startY + " " // line from center to first part of arc
    + createArc(pathData, 1)
    + "L " +  pathData.cx + " " + pathData.cy + " "; // line back to origin
}

function getTextArc(pathData, flag) {
    return "M " +  pathData.startX + " " + pathData.startY + " "  // start on the corner of arc
        + createArc(pathData, flag);
}

function createArc(pathData, flag) {
    return "A " + pathData.radius + " " + pathData.radius + " " // radius of arc (takes two for ellipses), our x and y radius are the same because circle
    + "0 " // x axis rotation (not an ellipse, we dont care)
    + "0 " // large arc flag (take the long path or short path)
    + flag + " " // sweep flag
    + pathData.endX + " " + pathData.endY + " " // end point;
}

function getCoordinatesForPercentAndNormalize(percent, radius, cx, cy) {
    const x = Math.cos(2 * Math.PI * percent) * radius;
    const y = Math.sin(2 * Math.PI * percent) * radius;
    return {x : x + cx, y: y + cy};
}

// TODO probably do that thing where you define data types expected (for the data field)

export default CenterCircle;
