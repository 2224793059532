import * as d3 from "d3";

const UPDATE_PIE_SLICE_MILLISECONDS = 200;

export function updatePieSliceSize(id, path) {
    // defaults to cubic ease function
    d3.select("#" + id)
        .transition(id + '-size')
        .duration(UPDATE_PIE_SLICE_MILLISECONDS)
        .attr('d', path);
}

export function updatePieSliceString(id, path) {
    // defaults to cubic ease function
    d3.select("#" + id)
        .transition(id + '-path')
        .duration(UPDATE_PIE_SLICE_MILLISECONDS)
        .attr('d', path);
}