import React, { Component } from 'react';
import TextPage from '../components/TextPage';
import Pdf from '../resources/resume.pdf';

class ResumePage extends Component {

  render() {
    const textElements = <div>
        <br/>
        <p>Find my resume <a href={Pdf}>here</a>.</p>
        <p>CV to be added soon.</p>
    </div>;

    return (
      <div> 
        <TextPage title={'Resume'} textElements={textElements}/>
      </div>
    );
  }
}

export { ResumePage };
